import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles'
import {theme} from '../../assets/mui'
import '../../assets/scss/IndexPage.scss';
import '../../assets/scss/typography.scss'
import '../../assets/css/layout.css'

const Page = ({children}) => {
	return <div className = 'page'>
		<MuiThemeProvider theme={theme}>
			<div className = 'sub-page'>{children}</div>
      		</MuiThemeProvider>
		</div>
}

export default Page