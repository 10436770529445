import React from "react"

import TextField from "@material-ui/core/TextField"
import { useField } from "formik"
import {makeStyles} from '@material-ui/core/styles'


const styles = makeStyles((theme) => ({
	textBox: {
    	fontFamily: 'visby',
    	marginBottom: '0.2em',
    	fontSize: '0.6em',
  },
}))

const CustomTextField = ({ type, label, placeholder, InputProps, ...props }) => {
  const [field, meta] = useField(props)
  const classes = styles()
  const errorText = meta.error && meta.touched ? meta.error : ""

  return (
    <TextField
      label={label}
      variant="outlined"
      required
      fullWidth
      type={type}
      placeholder={placeholder}
      {...field}
      helperText={errorText}
      error={!!errorText}
      InputProps={InputProps}
      className={classes.textBox}
    />
  )
}

export default CustomTextField

